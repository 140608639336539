@keyframes scaleIn {
  from {
    transform: rotateX(60deg) scale(.5, .5);
    opacity: .5;
  }
  to {
    transform: rotateX(60deg) scale(2.5, 2.5);
    opacity: 0;
  }
}

.pulse-circle {
  border-radius: 50%;
  background-color: #2661d2;
  width: 50px;
  height: 50px;
  position: absolute;
  opacity: 0;

  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation: scaleIn 3s infinite cubic-bezier(.36, .11, .89, .32);
      animation-delay: ($i - 1) * 1s;
    }
  }
}
